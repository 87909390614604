import React from 'react'
import { graphql, Link } from 'gatsby'

import Content from '../components/Content'
import Layout from '../components/Layout'
import Gallery from '../components/Gallery2'
import PageHeader from '../components/PageHeader'

// Export Template for use in CMS preview
export const AboutPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  video,
  videoPoster,
  videoTitle,
  section1,
  gallery,
  section2
}) => (
  <main className="DefaultPage">
    <PageHeader
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />
    <section className="section">
      <div className="container">
        <h1>Letter from CEO</h1>
        <div className="fotoCEO" />
        <Content source={section1} />
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h2 className="taCenter">Our Team</h2>
        <Gallery images={gallery} />
      </div>
    </section>
    <section className="section">
      <div className="container">
        <Content source={section2} />
      </div>
    </section>
    <section className="section">
      <h2 className="taCenter">Our Clients</h2>
      <div className="flex-klien">
          <Link to="/post-categories/case-study/" hreflang="en" aria-label="Philoshop Clients"><div className="pelanggan"/></Link>
      </div>

      <Link to="/contact/" hreflang="en" aria-label="Philoshop Business Support"><button className="tombol">Contact Us</button></Link> <br /> <br />
    </section>
  </main>
)

const AboutPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <AboutPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)
export default AboutPage

export const pageQuery = graphql`
  query AboutPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Gallery
      html
      frontmatter {
        title
        subtitle
        featuredImage
        video
        videoPoster
        videoTitle
        section1
        section2
      }
    }
  }
`
